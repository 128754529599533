import { Line, Column } from '../../layout'
import { Text, P } from '../../layout/Layout'
import { useState } from 'react'
import { PulseLoader } from 'react-spinners'
import { Input, PasswordStrength, Button } from './styles'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { changePassword } from '../../API/queries'
import SuccessMessage from './SuccessMessage'
import ErrorMessage from './ErrorMessage'
import TokenErrorMessage from './TokenErrorMessage'

export default function ChangePassword(){

    const [step, setStep] = useState('password')

    const [id, setId] = useState('')
    const [token, setToken] = useState('')

    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [passwordStrength, setPasswordStrength] = useState('weak')
    const [loading, setLoading] = useState(false)
    
    const [isError, setIsError] = useState(false)
    const [error, setError] = useState('')

    const navigate = useNavigate()

    const checkPasswordStrength = (password) => {
   
        const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
        const mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')
        
        if(strongPassword.test(password)) return 'strong'
        else if(mediumPassword.test(password)) return 'medium'
        else return 'weak'
    }

    useEffect(() => {
        if(password) setPasswordStrength(checkPasswordStrength(password))
    },[password])

    useEffect(() => {
    
        const recoverParam = () => { 
            const params = new URLSearchParams(window.location.search)
            const recovered_id = params.get('id')
            const recovered_token = params.get('token')

            if(!recovered_id || !recovered_token) navigate('/')

            setId(recovered_id)
            setToken(recovered_token)
        }
        
        recoverParam()
    },[])

    const handlePassword = () => {
        if(!password) {
            setIsError(true)
            setError('Informe a nova senha antes de prosseguir')
            return
        }

        if(checkPasswordStrength(password) !== 'strong') {
            setIsError(true)
            setError('A nova senha não é segura o suficiente. Utilize pele menos um número, uma letra maiúscula e um caractere especial')
            return
        }

        setStep('passwordConfirm')
    }

    const handlePasswordConfirm = async () => {
        if(password !== passwordConfirm) {
            setIsError(true)
            setError('A confirmação da senha deve ser idêntica')
            return
        }

        setLoading(true)

        changePassword(id, password, token).then(res => {
            setStep('success')
        }).catch(err => {
            if(err.response.status === 401) setStep('notoken')
            else setStep('error')
        }).finally(() => setLoading(false))
    }


    return(
        <>
            {
                step === 'password' ?
                <>
                    <Text size={18} weight={700} color={'#00BFA5'}>
                        Alterar Senha
                    </Text>
                    <P align={'center'} size={12} weight={500} color={'#aaa'}>
                        Insira <strong>uma senha forte</strong> com pelo menos um número, uma letra maiúscula e um caractere especial.
                    </P>
                    <Line height={25} width={40}/>
                    <Column align={'flex-end'} width={'20rem'}>
                        <Input type={'password'} value={password} id={'password'} placeholder='Digite a senha' onChange={(e) => {setPassword(e.target.value); setIsError(false)}}/>
                        {
                            password &&
                            <PasswordStrength strength={passwordStrength}>
                                {
                                    passwordStrength === 'weak' ? 'Senha Fraca' : passwordStrength === 'medium' ? 'Senha Média' : 'Senha Forte'
                                }
                            </PasswordStrength>
                        }
                    </Column>
                    {
                        isError &&
                        <Text weight={600} color={'red'}>{error}</Text>
                    }
                    <Button onClick={() => handlePassword()}>
                        Próximo
                    </Button>
                </>
                :
                step === 'passwordConfirm' ?
                <>
                    <Text size={18} weight={700} color={'#00BFA5'}>
                        Confirmar Senha
                    </Text>
                    <P align={'center'} size={12} weight={500} color={'#aaa'}>
                        Insira <strong>a senha novamente</strong> para confirmar.
                    </P>
                    <Line height={25} width={40}/>
                    <Column align={'flex-end'} width={'20rem'}>
                        <Input type={'password'} value={passwordConfirm} id={'passwordConfirm'} placeholder='Confirme sua senha' onChange={(e) => {setPasswordConfirm(e.target.value); setIsError(false)}}/>
                    </Column>
                    {
                        isError &&
                        <Text weight={600} color={'red'}>{error}</Text>
                    }
                    <Button onClick={() => handlePasswordConfirm()}>
                        {
                            loading ? 
                            <PulseLoader color={'white'} loading={true} size={8}/>
                            :
                            'Criar Senha'
                        } 
                    </Button>
                </>
                :
                step === 'success' ?
                <SuccessMessage/>
                :
                step === 'error' ?
                <ErrorMessage/>
                :
                step === 'notoken' &&
                <TokenErrorMessage/>
            }
        </>
    )
}
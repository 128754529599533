import styled from "styled-components"

export const Input = styled.input`
    height: 35px;
    width: 100%;
    padding: 0 10px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: #efefef;
    color: #888;
    font-size: 12px;
    font-weight: 400;

    &:hover{
        background-color: #e8e8e8;
    }
`

export const PasswordStrength = styled.h1`
    font-size: 1rem;
    font-weight: 600;
    color: ${props => props.strength === 'strong' ? '#00C853': props.strength === 'medium' ? '#FB9800' : props.strength === 'weak' && '#F34236'};
`

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 1rem;
    border: none;
    background-color: #00BFA5;
    width: 80%;
    min-height: 4rem;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: white;
    margin: 3rem 1rem 1rem 1rem;
    font-size: 16px;
    cursor: pointer;

    &:hover{
        background-color: #00897B;
    }
`
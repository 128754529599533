import api from './api'

export const auth = async (email, password) => {
    return await api.post('auth/authenticate', {email, password})
}

export const recoverPassword = async (email) => {
    return await api.post('auth/recover', {email})
}

export const createPassword = async (id, password) => {
    return await api.post('auth/createpassword', {id, password})
}

export const changePassword = async (id, password, token) => {
    return await api.post('auth/changepassword', {id, password}, {headers: {authorization: 'Bearer ' + token}})
}
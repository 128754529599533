import { TextLink, Spacer } from '../../layout'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import RecoverForm from './RecoverForm'
import EmailSentMessage from './EmailSentMessage'
import ErrorMessage from './ErrorMessage'

export default function PasswordRecover({}){

    const [step, setStep] = useState('email')    

    return(
        <>
            {
                step === 'email' ?
                <RecoverForm
                    onSuccess={() => setStep('success')}
                    onError={() => setStep('error')}
                />
                :
                step === 'success' ?
                <EmailSentMessage/>
                :
                step === 'error' &&
                <ErrorMessage/>
            }
            <Spacer height={1}/>
            <Link to={'/'}>
                <TextLink>
                    Fazer Login
                </TextLink>
            </Link>
        </>
    )
}
import { Box, Page, RightContainer } from './AppStyle'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LeftSection from './components/LeftSection';

import { AttendantLoginProvider } from './contexts/AttendantLoginProvider';

import Login from './components/Login'
import PasswordRecover from './components/PasswordRecover';
import PlaceSelector from './components/PlaceSelector';
import CreateNewPassword from './components/CreateNewPassword';
import ChangePassword from './components/ChangePassword';

function App() {
  return (
      <Page>
        <LeftSection/>
        <RightContainer>
          <AttendantLoginProvider>
            <Box>
              <Router>
                <Routes>
                  <Route path='/' element={<Login/>}/>
                  <Route path='/passwordrecover' element={<PasswordRecover/>}/>
                  <Route path='/placeselector' element={<PlaceSelector/>}/>
                  <Route path='/createpassword' element={<CreateNewPassword/>}/>
                  <Route path='/changepassword' element={<ChangePassword/>}/>
                </Routes>
              </Router>
            </Box>
          </AttendantLoginProvider>
        </RightContainer>
      </Page>
  );
}

export default App;

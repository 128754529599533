import { Line, Spacer } from '../../layout'
import { Text, Icon, P } from '../../layout/Layout'
import { IoWarningOutline } from 'react-icons/io5'


export default function ErrorMessage(){

    return(
        <>
            <Icon size={32} color='#FFA726'>
                <IoWarningOutline/>
            </Icon>
            <Spacer height={1}/>
            <Text size={18} weight={700} color={'#00BFA5'}>
                Ocorreu um erro
            </Text>
            <Line height={25} width={40}/>
            <P align={'center'} size={12} weight={500} color={'#aaa'}>
                Não foi possível concluir esta ação devido a algum problema interno. Por favor, contate-nos para solucionarmos este incoveniente.
            </P>
        </>
    )
}
import { BsCheck2Circle } from 'react-icons/bs'
import { Icon } from '../../layout'
import { Text, P } from '../../layout/Layout'
import { Button } from './styles'
import { useNavigate } from 'react-router-dom'

export default function SuccessMessage(){

    const navigate = useNavigate()

    return(
        <>
            <Icon size={5} color={'#00BFA5'}>
                <BsCheck2Circle/>
            </Icon>
            <Text size={18} weight={700} color={'#00BFA5'}>
                Senha criada!
            </Text>
            <P align={'center'} size={12} weight={500} color={'#aaa'}>
                Agora você pode fazer o <strong>login no Sistema Phila!</strong>
            </P>
            <Button onClick={() => navigate('/')}>
                Fazer Login
            </Button>
        </>
    )
}
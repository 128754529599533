import { BsExclamationTriangle } from 'react-icons/bs'
import { Icon, TextLink } from '../../layout'
import { Text, P } from '../../layout/Layout'
import { useNavigate } from 'react-router-dom'

export default function ErrorMessage(){

    const navigate = useNavigate()

    return(
        <>
            <Icon size={5} color={'#FB9800'}>
                <BsExclamationTriangle/>
            </Icon>
            <Text size={18} weight={700} color={'#00BFA5'}>
                Não foi possível completar a ação
            </Text>
            <P align={'center'} size={12} weight={500} color={'#aaa'}>
                Se necessário <strong>entre em contato com o suporte técnico</strong> para solucionar o problema
            </P>
            <TextLink onClick={() => navigate('/')}>
                Voltar
            </TextLink>
        </>
    )
}
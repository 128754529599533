import styled from 'styled-components'
import { Line, Select, Option, Column, Icon, Spacer } from '../../layout'
import { Text, Row, P } from '../../layout/Layout'
import { useState } from 'react'
import DeksButton from './DeskButton'
import { RiHome3Line } from 'react-icons/ri'
import { useContext } from 'react'
import { AttendantLoginContext } from '../../contexts/AttendantLoginProvider'
import { useEffect } from 'react'

const Buttons = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 6rem;
    grid-gap: 1rem;
    width: 100%;
    overflow: auto;

    &::-webkit-scrollbar{
        width: 10px;
    }

    &::-webkit-scrollbar-track{
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb{
        background: #B0BEC5;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover{
        background: #90A4AE;
    }
`

export default function PlaceSelector({}){

    const { attendantAuthData } = useContext(AttendantLoginContext)

    const { places, desks, token, user } = attendantAuthData

    const [selectedPlace, setSelectedPlace] = useState(null)

    useEffect(() => {
        console.log(places)
        console.log(desks)
        if(places.length === 1) setSelectedPlace(places[0]._id)
    },[])

    const handleDeskSelect = (desk) => {
        
        if(desk.status !== 'inactive') return
        
        handleAttendantLogin(desk._id)
    }

    const handleAttendantLogin = (deskId) => {
        const appUrl = process.env.REACT_APP_PCA_URL

        const params = new URLSearchParams()

        params.append('token', token)
        params.append('userId', user.userId)
        params.append('attendantId', user._id)
        params.append('place', places.find(el => el._id === selectedPlace).name)
        params.append('deskId', deskId)
        
        window.location.replace(appUrl + '?' + params.toString())
    }

    return(
        <>
            <Text size={18} weight={700} color={'#00BFA5'}>
                Em qual guichê você irá operar?
            </Text>
                {
                    places.length === 1 ?
                    <P align={'center'} size={12} weight={500} color={'#aaa'}> 
                        Selecione abaixo em qual <strong>guichê que você irá operar</strong>
                    </P>
                    :
                    <P align={'center'} size={12} weight={500} color={'#aaa'}> 
                        Selecione abaixo em qual <strong>Local você irá atender</strong> e o <strong>guichê que você irá operar</strong>
                    </P>
                }
            <Line height={25} width={40}/>
            {
                places.length > 1 &&
                <>
                    <Row>
                        <Icon color={'#00BFA5'}>
                            <RiHome3Line/>
                        </Icon>
                        <Select name={'place'} onChange={(e) => setSelectedPlace(e.target.value)}>
                            <Option value="" selected hidden>Selecione o Local</Option>
                            {
                                places.map((e, i) => {
                                    return <Option value={e._id} key={i}>{e.name}</Option>
                                })
                            }
                        </Select>
                    </Row>
                    <Line height={25} width={60}/>
                </>
            }
            <Column align={'center'}>
                {
                    !selectedPlace ? 
                    <>
                        <Text size={16} weight={500} color={'#aaa'}>Selecione o local</Text>
                        <Text size={16} weight={500} color={'#aaa'}>para mostrar os guichês</Text>
                    </>
                    :
                    desks.filter(el =>  el.placeId === selectedPlace).length > 0 ?
                    <>
                        <Text size={16} weight={500} color={'#aaa'}>Selecione o Guichê</Text>
                        <Spacer height={1}/>
                        <Buttons>
                            {
                                desks.filter(el => el.placeId === selectedPlace).sort((a,b) => a.number-b.number).map((e, i) => {
                                    return <DeksButton key={i} data={e} onSelect={() => handleDeskSelect(e)}/>
                                })
                            }
                        </Buttons>
                    </>
                    :
                    <>
                        <Text size={16} weight={500} color={'#aaa'}>Este local não possui</Text>
                        <Text size={16} weight={500} color={'#aaa'}>guichês cadastrados</Text>
                    </>
                }
            </Column>
        </>
    )
}
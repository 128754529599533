import { BsExclamationTriangle } from 'react-icons/bs'
import { Icon, TextLink } from '../../layout'
import { Text, P } from '../../layout/Layout'
import { useNavigate } from 'react-router-dom'

export default function TokenErrorMessage(){

    const navigate = useNavigate()

    return(
        <>
            <Icon size={4} color={'#FB9800'}>
                <BsExclamationTriangle/>
            </Icon>
            <Text size={18} weight={700} color={'#00BFA5'}>
                Solicitação expirada
            </Text>
            <P align={'center'} size={12} weight={500} color={'#aaa'}>
                Sua solicitação de recuperação de senha foi expirada. Se desejar refaça a solicitação
            </P>
            <TextLink onClick={() => navigate('/')}>
                Voltar
            </TextLink>
        </>
    )
}
import styled from 'styled-components'

export const Page = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #00BFA5;
`

export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: auto;
    padding: 6rem;
    align-items: center;
    justify-content: flex-end;
    width: 60vw;
    height: 100vh;
    background-color: white;
    text-align: center;
`

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: white;
    border-radius: 1.5rem;
    width: 45%;
    height: fit-content;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #424242;
    padding: 4rem 2rem;
    transition-duration: 1s;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

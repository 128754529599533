import { Line, Form, Input, Submit } from '../../layout'
import { Text, Icon, Row, P } from '../../layout/Layout'
import { HiOutlineKey } from 'react-icons/hi'
import { useState } from 'react'
import { PulseLoader } from 'react-spinners'
import { recoverPassword } from '../../API/queries'

export default function RecoverForm({onSuccess, onError}){

    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    
    const [isError, setIsError] = useState(false)
    const [error, setError] = useState('')
    
    const doRecover = async () => {
        setLoading(true)

        await recoverPassword(email).then((res) => {
            console.log(res.status)
            onSuccess()
        }).catch((err) => {
            if(err.response.status === 404) {
                setError('E-mail inválido. Tente novamente!')
                setIsError(true)
            }
            else if(err.response.status === 400) onError()
        }).finally(() => setLoading(false))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if(!email) {
            setIsError(true)
            setError('Insira o e-mail corretamente')
            return
        }
        
       doRecover()
    }

    return(
        <>
            <Text size={18} weight={700} color={'#00BFA5'}>
                Recuperação de Senha
            </Text>
            <P align={'center'} size={12} weight={500} color={'#aaa'}>
                Insira <strong>seu e-mail</strong> para recuperar a sua senha
            </P>
            <Line height={25} width={40}/>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Row align={'center'} height={15}>
                    <Icon size={22} color={'#00BFA5'} width={25}>
                        <HiOutlineKey/>
                    </Icon>
                    <Input type={'text'} name={'user'} placeholder='Insira seu E-mail' onChange={(e) => {setEmail(e.target.value); setIsError(false)}}/>
                </Row>
                <Submit type='submit'>
                    {
                        loading ? 
                        <PulseLoader color={'white'} loading={true} size={8}/>
                        :
                        'Enviar'
                    }
                </Submit>
            </Form>
            {
                isError &&
                <Text weight={600} color={'red'}>{error}</Text>
            }
        </>
    )
}
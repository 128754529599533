import styled from "styled-components"


export const Row = styled.span`
    display: flex;
    align-items: ${props => props.align};
    justify-content: ${props => props.justify};
    width: 100%;
    height: ${props => (typeof props.height === 'string' ? props.height : typeof props.height === 'number' ? props.height+'\%' : 100+'\%')};
    min-height: ${props => (typeof props.height === 'string' ? props.height : typeof props.height === 'number' ? props.height+'\%' : 100+'\%')};
    padding-top: ${props => props.top}rem;
    padding-bottom: ${props => props.bottom}rem;
    padding-left: ${props => props.left}rem;
    padding-right: ${props => props.right}rem;
    border-bottom-style: ${props => props.borderBtmStyle};
    border-bottom-width: ${props => props.borderBtmWidth}px;
    border-bottom-color: ${props => props.borderBtmColor};
    background-color: ${props => props.color};

    &:hover{
        background-color: ${props => props.hoverColor};
    }
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.align};
    justify-content: ${props => props.justify};
    margin-left: ${props => props.left}rem;
    margin-right: ${props => props.right}rem;
    padding: ${props => props.padding}rem;
    width: ${props => (typeof props.width === 'string' ? props.width : typeof props.width === 'number' ? props.width+'\%' : 100+'\%')};
    height: ${props => props.height && props.height};
    border-left: ${props => props.leftLine && '1px solid #ccc'};
    border-right: ${props => props.rightLine && '1px solid #ccc'};
`

export const Text = styled.h1`
    font-size: ${props => props.size ? props.size : 1}rem;
    font-weight: ${props => props.weight ? props.weight : 400};
    color: ${props => props.color ? props.color : '#424242'};
    text-align: ${props => props.align && props.align};
    text-decoration: ${props => props.decoration};
    cursor: ${props => props.pointer && 'pointer'};
    white-space: ${props => props.wrap ? 'pre' : 'nowrap'};
    margin: ${props => props.margin}rem;

    &:hover{
        color: ${props => props.hover && '#1565C0'};
    }
`

export const Icon = styled.div`
    display: flex;
    align-items: center;
    margin: ${props => props.margin ? props.margin : 0.5}rem;
    padding-top: ${props => props.top && props.top}rem;
    padding-bottom: ${props => props.bottom && props.bottom}rem;
    padding-left: ${props => props.left && props.left}rem;
    padding-right: ${props => props.right && props.right}rem;
    cursor: ${props => props.pointer && 'pointer'};
    
    svg{
        color: ${props => props.color ? props.color : '#424242'};
        font-size: ${props => props.size ? props.size : 2}rem;
        width: ${props => props.size ? props.size : 2}rem;
        height: ${props => props.size ? props.size : 2}rem;
    
        path{
            fill: ${props => props.fill};
        }

        
        &:hover{
            color: ${props => props.hoverColor};
            font-size: ${props => props.hoverSize}rem;
        }

        &:active{
            color: ${props => props.activeColor};
            font-size: ${props => props.activeSize}rem;
       }
    
    }
`

export const Submit = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 1rem;
    border: none;
    background-color: #00BFA5;
    width: 80%;
    min-height: 4rem;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: white;
    margin: 3rem 1rem 1rem 1rem;
    font-size: 16px;
    cursor: pointer;

    &:hover{
        background-color: #00897B;
    }
`

export const BackBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: white;
    min-height: 2.5rem;
    color: #00BFA5;
    font-size: 1.2rem;
    font-weight: 400;
    cursor: pointer;

    svg{
        margin-top: 0.1rem;
        font-size: 1.5rem;
    }
            
    &:hover{
        font-weight: 600;
    
        svg{
            font-size: 1.7rem;
        }
    }
`

export const Button = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    border: 1px solid #00BFA5;
    background-color: white;
    width: 100%;
    min-height: 8rem;
    color: #00BFA5;
    margin: 3rem 1rem 1rem 1rem;
    font-size: 16px;
    cursor: pointer;

    svg{
        color: ${props => props.color};
        font-size: ${props => props.size}rem;
        width: ${props => props.size}rem;
        height: ${props => props.size}rem;
    
        path{
            fill: ${props => props.fill};
        }
    }
            
    &:hover{
        background-color: #00BFA5;
        color: white;
    
        svg{
            color: ${props => props.hoverColor};

            path{
                fill: ${props => props.hoverFill};
            }
        }
    }

    &:active{
        background-color: #26A69A;
    }

`

export const Select = styled.select`
    height: 35px;
    width: 100%;
    margin: 10px;
    padding: 0 10px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: #efefef;
    color: #888;
    font-size: 12px;
    font-weight: 400;

    &:hover{
        background-color: #e8e8e8;
    }
`

export const Option = styled.option`
    height: 30px;
    font-style: ${props => props.textStyle};
    font-size: 12px;
    color: ${props => props.color ? props.color : '#424242'};
    background-color: white;
    outline: none;
    border: none;
    accent-color: #00BFA5;
`

export const Input = styled.input`
    height: 35px;
    width: 100%;
    margin: 10px;
    padding: 0 10px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: #efefef;
    color: #888;
    font-size: 12px;
    font-weight: 400;

    &:hover{
        background-color: #e8e8e8;
    }
`

export const Line = styled.div`
    height: ${props => props.height && props.height}px;
    border-bottom: 1px solid #00BFA5;
    margin-bottom: ${props => props.height && props.height}px;
    width: ${props => props.width && props.width}%;
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`

export const Spacer = styled.div`
    margin-top: ${props => props.height}rem;
    margin-left: ${props => props.width}rem;
`

export const TextLink = styled.label`
    font-size: 1rem;
    font-weight: 400;
    color: #1565C0;
    text-decoration: underline;
    cursor: pointer;
    white-space: ${props => props.wrap};

    &:hover{
        font-weight: 500;
    }
`
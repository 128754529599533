import { Line, Form, Input, Submit, TextLink, Spacer } from '../../layout'
import { Text, Icon, Row, P } from '../../layout/Layout'
import { BiLockAlt } from 'react-icons/bi'
import { HiOutlineKey } from 'react-icons/hi'
import { useState, useContext } from 'react'
import { PulseLoader } from 'react-spinners'
import { auth } from '../../API/queries'
import { Link } from 'react-router-dom'
import { AttendantLoginContext } from '../../contexts/AttendantLoginProvider'
import { useNavigate } from 'react-router-dom'

export default function Login({}){

    const { setAttendantAuthData } = useContext(AttendantLoginContext)

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')

    const [loading, setLoading] = useState(false)
    
    const [isError, setIsError] = useState(false)
    const [error, setError] = useState('')

    const navigate = useNavigate()
    
    const handleSubmit = (e) => {
        e.preventDefault()

        if(!login || !password) {
            setIsError(true)
            setError('Insira suas credenciais corretamente')
            return
        }
        
       doLogin()
    }

    const doLogin = async () => {

        setLoading(true)

        await auth(login, password).then((res) => {
            handleAuthSuccess(res.data)
        }).catch((err) => {
            console.log(err)
            if(err.response.status === 404) {
                setIsError(true)
                setError('Este e-mail está incorreto')
                setLoading(false)
            }
            if(err.response.status === 403) {
                setIsError(true)
                setError('Acesso negado!')
                setLoading(false)
            }
            if(err.response.status === 401) {
                setIsError(true)
                setError('A senha está incorreta')
                setLoading(false)
            }
        })
    }

    const handleAuthSuccess = (data) => {

        console.log('data: ', data)
        if(data.type === 'admin') return handleAdminLogin(data)        

        setAttendantAuthData(data)
        navigate('/placeselector')
    }

    const handleAdminLogin = (data) => {
        const appUrl = process.env.REACT_APP_SGF_URL

        const params = new URLSearchParams()

        params.append('token', data.token)
        params.append('userId', data.user.userId)
        params.append('adminId', data.user._id)

        window.location.replace(appUrl + '?' + params.toString())
    }

    return(
        <>
            <Text size={18} weight={700} color={'#00BFA5'}>
                Entrar
            </Text>
            <P align={'center'} size={12} weight={500} color={'#aaa'}>
                Insira <strong>suas credencias</strong> para iniciar a secção
            </P>
            <Line height={25} width={40}/>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Row align={'center'} height={15}>
                    <Icon size={22} color={'#00BFA5'} width={25}>
                        <HiOutlineKey/>
                    </Icon>
                    <Input type={'text'} name={'user'} placeholder='Insira seu E-mail' onChange={(e) => {setLogin(e.target.value); setIsError(false)}}/>
                </Row>
                <Row align={'center'} height={15}>
                    <Icon size={22} color={'#00BFA5'} width={25}>
                        <BiLockAlt/>
                    </Icon>
                    <Input type={'password'} name={'password'} placeholder='Insira sua Senha' onChange={(e) => {setPassword(e.target.value); setIsError(false)}}/>
                </Row>
                <Submit type='submit'>
                    {
                        loading ? 
                        <PulseLoader color={'white'} loading={true} size={8}/>
                        :
                        'Entrar'
                    }
                </Submit>
            </Form>
            {
                isError &&
                <Text weight={600} color={'red'}>{error}</Text>
            }
            <Spacer height={1}/>
            <Link to={'/passwordrecover'}>
                <TextLink>
                    Esqueci minha senha
                </TextLink>
            </Link>
        </>
    )
}
import styled from "styled-components"

const Button = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border: 1px solid ${props => props.disabled ? 'white' : '#00BFA5'};
    border-radius: 0.5rem;
    cursor: ${props => !props.disabled && 'pointer'};
    background-color: ${props => props.disabled && '#CFD8DC'};

    h1{
        font-size: 1rem;
        color: ${props => props.disabled ? '#90A4AE' : '#00BFA5'};
        font-weight: 500;
    }

    h2{
        font-size: 1.5rem;
        color: ${props => props.disabled ? '#90A4AE' : '#00BFA5'};
        font-weight: 800;
    }

    :hover{
        background-color: ${props => !props.disabled && '#00BFA5'};

        h1{
            color: ${props => !props.disabled && 'white'};
        }

        h2{
            color: ${props => !props.disabled && 'white'};
        }
    }
`

export default function DeksButton({data, onSelect}){


    return(
        <Button disabled={data.status !== 'inactive'} onClick={onSelect}>
            <h1>{data.name.split(' ')[0]}</h1>
            <h2>{data.name.split(' ')[1]}</h2>
        </Button>
    )
}
import { createContext, useState } from 'react'

export const AttendantLoginContext = createContext({})

export const AttendantLoginProvider = ({children}) => {
    
    const [attendantAuthData, setAttendantAuthData] = useState(null)

    
    return (
        <AttendantLoginContext.Provider value={{attendantAuthData, setAttendantAuthData}}>
            {children}
        </AttendantLoginContext.Provider>
    )
}
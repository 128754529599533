import { Line, Spacer } from '../../layout'
import { Text, Icon, P } from '../../layout/Layout'
import { BsCheck2Circle } from 'react-icons/bs'


export default function EmailSentMessage({}){

    return(
        <>
            <Icon size={48} color={'#00BFA5'}>
                <BsCheck2Circle/>
            </Icon>
            <Spacer height={1}/>
            <Text size={18} weight={700} color={'#00BFA5'}>
                E-mail enviado
            </Text>
            <Line height={25} width={40}/>
            <P align={'center'} size={12} weight={500} color={'#aaa'}>
                Enviamos um e-mail com as instruções para realizar a recuperação de sua senha. Por favor, <strong>acesse seu e-mail e siga os passos</strong> para concluir esta ação.
            </P>
        </>
    )
}